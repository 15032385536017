import { styled } from 'styled-components';

export const StyledCircle = styled.div<{ $isMobile?: boolean }>`
  background-color: #b50000;
  color: white;
  border-radius: 50%;
  width: ${({ $isMobile }) => ($isMobile ? '70px' : '100px')};
  height: ${({ $isMobile }) => ($isMobile ? '70px' : '100px')};
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: ${({ $isMobile }) => ($isMobile ? '280px' : '500px')};
  margin-bottom: -100px;
`;

export const StyledArrow = styled.svg<{ $isMobile: boolean }>`
  margin-left: ${({ $isMobile }) => ($isMobile ? '200px' : '380px')};
  margin-bottom: ${({ $isMobile }) => ($isMobile ? '-40px' : '-63px')};
  margin-top: 55px;
`;
