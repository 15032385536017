import axios from 'axios';
import { Fragment, useState } from 'react';

import { useIsMobile } from '../../../hooks';
import { TGameData, TParticipant, TParticiparteResponse } from '../../../types';

import { participate } from '../../../apiRoutes';
import Card from '../../common/Card';
import ClickHereCircle from './ClickHereCircle';
import DescriptionCard from './DescriptionCard';
import Footer from './Footer';
import LogoCard from './LogoCard';
import LoserCard from './LoserCard';
import WinnerCard from './WinnerCard';
import {
  StyledFortuneWheelContainer,
  StyledGameContainer,
  StyledGameContainerMobile,
  StyledGameName,
  StyledGrid,
  StyledPointer,
  StyledRightContainer,
  StyledWheel,
} from './style';

interface TFortuneWheelProps {
  game: TGameData;
  participant: TParticipant;
  userToken: string;
  imagesPath?: string;
  footerColor?: string;
}

const FortuneWheel: React.FC<TFortuneWheelProps> = ({
  game,
  userToken,
  imagesPath = 'assets/classicWheel',
  footerColor,
}: TFortuneWheelProps) => {
  const isMobile = useIsMobile();

  const [preRequestSpinning, setPreRequestSpinning] = useState(false);
  const [postRequestSpinning, setPostRequestSpinning] = useState(false);
  const [finalRotationDegree, setFinalRotationDegree] = useState(0);
  const [gameResult, setGameResult] = useState<TParticiparteResponse['data'] | false>(false);

  const launchWheel = async () => {
    if (gameResult) return;

    const rotationDegree = 360 * 8;
    setPreRequestSpinning(true);

    const { data }: TParticiparteResponse = await axios.get(participate(userToken));

    setPreRequestSpinning(false);
    setPostRequestSpinning(true);

    if (data.winner) {
      setFinalRotationDegree(rotationDegree + 23);
      setTimeout(() => {
        setGameResult(data);
      }, 2500);
    } else {
      setFinalRotationDegree(rotationDegree + 250);
      setTimeout(() => {
        setPostRequestSpinning(false);
        setGameResult(data);
      }, 2500);
    }
  };

  return (
    <Fragment>
      {isMobile ? (
        <Fragment>
          <StyledGameContainerMobile $imgPath={imagesPath}>
            <StyledGameName src={require(`../../../${imagesPath}/gameNameMobile.png`)} $isMobile />
            <StyledFortuneWheelContainer $isMobile>
              <ClickHereCircle />
              <StyledPointer src={require(`../../../${imagesPath}/pointeur.png`)} />
              <StyledWheel
                $preRequestSpinning={preRequestSpinning}
                $postRequestSpinning={postRequestSpinning}
                $finalRotationDegree={finalRotationDegree}
                onClick={launchWheel}
                $hasPlayed={!!gameResult}
                $isMobile
                $imgPath={imagesPath}
              />
            </StyledFortuneWheelContainer>
          </StyledGameContainerMobile>
          <Fragment>
            {gameResult ? (
              <Card
                maxWidth="500px"
                width="fit-content"
                padding="20px 24px"
                noShadows
                marginBottom="20px"
                borderRadius="0"
                margin="-50px 0px 30px 0px"
              >
                {gameResult && gameResult?.winner && <WinnerCard gameResult={gameResult} />}
                {gameResult && !gameResult?.winner && <LoserCard gameResult={gameResult} />}
              </Card>
            ) : (
              <Fragment>
                <Card
                  maxWidth="500px"
                  width="fit-content"
                  padding="20px 24px"
                  noShadows
                  marginBottom="20px"
                  borderRadius="0"
                  margin="-30px 0px 30px 0px"
                >
                  <LogoCard logoSrc={game.logo} />
                  <DescriptionCard game={game} />
                </Card>
              </Fragment>
            )}
          </Fragment>
          <Footer game={game} />
        </Fragment>
      ) : (
        <Fragment>
          <StyledGameContainer $imgPath={imagesPath}>
            <StyledGrid>
              <StyledRightContainer>
                <StyledGameName src={require(`../../../${imagesPath}/gameName.png`)} />
                <Card
                  maxWidth="402px"
                  width={isMobile ? 'fit-content' : ''}
                  padding={isMobile ? '20px 24px' : '100px 24px'}
                  noShadows={isMobile}
                  marginBottom="20px"
                  borderRadius="0"
                >
                  <LogoCard logoSrc={game.logo} />
                  {!gameResult && <DescriptionCard game={game} />}
                  {gameResult && gameResult?.winner && <WinnerCard gameResult={gameResult} />}
                  {gameResult && !gameResult?.winner && <LoserCard gameResult={gameResult} />}
                </Card>
              </StyledRightContainer>
              <StyledFortuneWheelContainer>
                <ClickHereCircle />
                <StyledPointer src={require(`../../../${imagesPath}/pointeur.png`)} />
                <StyledWheel
                  $preRequestSpinning={preRequestSpinning}
                  $postRequestSpinning={postRequestSpinning}
                  $finalRotationDegree={finalRotationDegree}
                  onClick={launchWheel}
                  $hasPlayed={!!gameResult}
                  $imgPath={imagesPath}
                />
              </StyledFortuneWheelContainer>
            </StyledGrid>
            <Footer game={game} footerColor={footerColor} />
          </StyledGameContainer>
        </Fragment>
      )}
    </Fragment>
  );
};

export default FortuneWheel;
