import { styled } from 'styled-components';

export const StyledContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ $isMobile }) => ($isMobile ? 'fit-content' : 'fit-content')};
  min-height: ${({ $isMobile }) => ($isMobile ? '80%' : '90%')};
  width: ${({ $isMobile }) => ($isMobile ? 'fit-content' : 'fit-content')};
  min-width: ${({ $isMobile }) => ($isMobile ? '80%' : '80%')};
  border: 4px solid white;
  flex-direction: column;
`;

export const StyledBottomText = styled.div`
  color: white;
  font-size: 16px;
  text-align: center;
  margin-top: 8px;
`;
