import styled, { css } from 'styled-components';

import { getSpinKeyframes } from '../../../animations';

export const StyledGameContainer = styled.div<{ $imgPath: string }>`
  background-image: ${({ $imgPath }) => `url(${require(`../../../${$imgPath}/background.png`)})`};
  background-size: auto;
  background-repeat: repeat;
  z-index: 30;
  padding: 40px 0;
  min-height: calc(100vh - 80px);
`;

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const StyledFortuneWheelContainer = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: ${({ $isMobile }) => ($isMobile ? '0' : '136px')};
`;

export const StyledPointer = styled.img`
  background-size: contain;
  height: 55px;
  width: 32px;
  z-index: 10;
  position: relative;
  margin-bottom: -25px;
`;

export const StyledGameName = styled.img<{ $isMobile?: boolean }>`
  margin-bottom: 15px;
  margin-top: -40px;
  max-width: ${({ $isMobile }) => $isMobile && '80%'};
  margin: ${({ $isMobile }) => $isMobile && '0 10%'};
`;

export const StyledWheel = styled.div<{
  $preRequestSpinning: boolean;
  $postRequestSpinning: boolean;
  $finalRotationDegree: number;
  $hasPlayed: boolean;
  $isMobile?: boolean;
  $imgPath: string;
}>`
  background-image: ${({ $imgPath }) => `url(${require(`../../../${$imgPath}/wheel.png`)})`};

  width: ${({ $isMobile }) => ($isMobile ? '300px' : '500px')};
  height: ${({ $isMobile }) => ($isMobile ? '288px' : '500px')};
  background-size: contain;
  position: relative;
  z-index: 1;
  cursor: pointer;
  cursor: ${({ $hasPlayed }) => ($hasPlayed ? 'not-allowed' : 'pointer')};

  ${({ $preRequestSpinning, $finalRotationDegree, $postRequestSpinning }) =>
    $preRequestSpinning
      ? css`
          animation: ${getSpinKeyframes(940)} 2.5s linear infinite;
        `
      : $finalRotationDegree && $postRequestSpinning
      ? css`
          animation: ${getSpinKeyframes($finalRotationDegree)} 2.5s linear 1 forwards;
        `
      : 'none'};
`;

export const StyledRightContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const StyledText = styled.div`
  font-size: 24px;
  text-align: center;
`;

export const StyledGameContainerMobile = styled.div<{ $imgPath: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: ${({ $imgPath }) => `url(${require(`../../../${$imgPath}/background.png`)})`};
  background-size: 185% 458px;
  background-repeat: no-repeat;
  height: 100%;
  z-index: 30;
  padding: 40px 0;
`;

export const StyledLoserTitle = styled.div`
  font-family: Lobster;
  text-align: center;
  font-size: 110px;
  color: #fbcf63;
  text-shadow: -4px -4px 0 #f9b818, 4px -4px 0 #f9b818, -4px 4px 0 #f9b818, 4px 4px 0 #f9b818;
`;
