import { styled } from 'styled-components';

export const StyledContainer = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 40px;
  text-align: center;
  font-size: 1.2rem;

  @media (max-width: 768px) {
    width: 100%;
    padding: 70px 0px;
  }
`;
