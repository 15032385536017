import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';

import { TGameData, TParticipant } from './types';

import AlertToDisplay from './components/AlertToDisplay';
import NoGameAvailable from './components/NoGameAvailable';
import Loader from './components/common/Loader';
import Game from './components/games';

import { BASE_URL, gameRoute } from './apiRoutes';
import { isFutureGame, isPastGame } from './helpers';
import { translate } from './translations';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [game, setGame] = useState<TGameData | false>(false);
  const [participant, setParticipant] = useState<TParticipant | false>(false);

  const userToken = window.location.pathname.substring(1);

  const getCurrentGame = async () => {
    try {
      const { data } = await axios.get(gameRoute(userToken));
      setGame(data.game);
      setParticipant(data.participant);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCurrentGame();
  }, [BASE_URL]);

  if (loading) return <Loader />;

  document.title = game ? game.title : translate('gameNotAvailablePageTitle');

  if (!game || !participant || !userToken || !game.enabled || isPastGame(game.endDate) || isFutureGame(game.startDate))
    return <NoGameAvailable />;

  return (
    <Fragment>
      <Game game={game} participant={participant} userToken={userToken} />
      <AlertToDisplay game={game} participant={participant} />
    </Fragment>
  );
};

export default App;
