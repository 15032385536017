import { styled } from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
`;

export const StyledTitle = styled.div<{ $isMobile: boolean }>`
  font-family: 'Inknut Antiqua', serif;
  font-size: ${({ $isMobile }) => ($isMobile ? '50px' : '4em')};
  line-height: 1.2;
  color: white;
  text-transform: uppercase;
  text-align: center;
`;

export const StyledSubtitle = styled.div<{ $isMobile: boolean }>`
  font-size: ${({ $isMobile }) => ($isMobile ? '30px' : '2.5em')};
  color: white;
  text-align: center;
`;

export const StyledPresentImage = styled.img<{ $isMobile: boolean }>`
  height: ${({ $isMobile }) => ($isMobile ? '200px' : '16em')};
  margin-bottom: ${({ $isMobile }) => ($isMobile ? '20px' : '0')};
`;

export const StyledBottomText = styled.div<{ $isMobile: boolean }>`
  font-size: ${({ $isMobile }) => ($isMobile ? '22px' : '1.5em')};
  line-height: 1.2;
  color: white;
  max-width: ${({ $isMobile }) => ($isMobile ? '90%' : '80%')};
  text-align: center;
`;
