import { styled } from 'styled-components';

export const StyledMobileViewContainer = styled.div`
  overflow: hidden;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledTopContainer = styled.div`
  background-color: #222b3a;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  margin-bottom: 20px;
`;

export const StyledGameTitle = styled.div`
  font-family: 'Inknut Antiqua', serif;
  color: white;
  text-transform: uppercase;
  font-size: 38px;
  line-height: 1.2;
  width: 350px;
  text-align: center;
  margin-bottom: 20px;
`;

export const StyledLogo = styled.img`
  height: 80px;
  margin-bottom: -40px;
  z-index: 1;
`;

export const StyledDayContainer = styled.div`
  width: 100%;
  height: 400px;
  background-image: url(${require(`../../../../assets/adventCalendar/background.png`)});
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
`;

export const StyledBottomContainer = styled.div`
  background-color: #222b3a;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-bottom: 20px;
`;

export const StyledDates = styled.div`
  color: white;
  margin-top: -20px;
  width: 100%;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
`;

export const StyledWelcomeTitle = styled.div`
  color: white;
  font-size: 28px;
  text-align: center;
  width: 300px;
  margin-top: 10px;
`;

export const StyledHaveFunText = styled.div`
  margin-top: 20px;
  color: #d4a26d;
  text-align: center;
  margin-bottom: 20px;
`;
