import axios from 'axios';
import { Fragment, useState } from 'react';

import { useIsMobile } from '../../../hooks';
import { TGameData, TParticipant, TParticiparteResponse } from '../../../types';

import { participate } from '../../../apiRoutes';
import ClickHereCircle from './ClickHereCircle';
import DescriptionCard from './DescriptionCard';
import Footer from './Footer';
import LogoCard from './LogoCard';
import LoserCard from './LoserCard';
import WinnerCard from './WinnerCard';
import {
  StyledFortuneWheelContainer,
  StyledGameContainer,
  StyledGameContainerMobile,
  StyledGameName,
  StyledPointer,
  StyledPresents,
  StyledRightContainer,
  StyledWheel,
} from './style';

interface TFortuneWheelProps {
  game: TGameData;
  participant: TParticipant;
  userToken: string;
}

const FortuneWheel: React.FC<TFortuneWheelProps> = ({ game, userToken }: TFortuneWheelProps) => {
  const isMobile = useIsMobile();

  const [preRequestSpinning, setPreRequestSpinning] = useState(false);
  const [postRequestSpinning, setPostRequestSpinning] = useState(false);
  const [finalRotationDegree, setFinalRotationDegree] = useState(0);
  const [gameResult, setGameResult] = useState<TParticiparteResponse['data'] | false>(false);

  const launchWheel = async () => {
    if (gameResult) return;

    const rotationDegree = 360 * 45;
    setPreRequestSpinning(true);

    const { data }: TParticiparteResponse = await axios.get(participate(userToken));

    setPreRequestSpinning(false);
    setPostRequestSpinning(true);

    if (data.winner) {
      setFinalRotationDegree(rotationDegree + 23);
      setTimeout(() => {
        setGameResult(data);
      }, 3000);
    } else {
      setFinalRotationDegree(rotationDegree + 250);
      setTimeout(() => {
        setPostRequestSpinning(false);
        setGameResult(data);
      }, 3000);
    }
  };

  return (
    <Fragment>
      {isMobile ? (
        <StyledGameContainerMobile>
          <StyledGameName src={require('../../../assets/christmasFortuneWheel/gameName.png')} $isMobile />
          <LogoCard logoSrc={game.logo} />
          {gameResult ? (
            <Fragment>
              {gameResult && gameResult?.winner && <WinnerCard gameResult={gameResult} />}
              {gameResult && !gameResult?.winner && <LoserCard gameResult={gameResult} />}
            </Fragment>
          ) : (
            <Fragment>
              <DescriptionCard game={game} />
              <StyledFortuneWheelContainer>
                <ClickHereCircle />
                <StyledPointer src={require(`../../../assets/christmasFortuneWheel/pointeur.png`)} />
                <StyledWheel
                  $preRequestSpinning={preRequestSpinning}
                  $postRequestSpinning={postRequestSpinning}
                  $finalRotationDegree={finalRotationDegree}
                  onClick={launchWheel}
                  $hasPlayed={!!gameResult}
                  $isMobile
                />
                <StyledPresents src={require(`../../../assets/christmasFortuneWheel/presents.png`)} $isMobile />
              </StyledFortuneWheelContainer>
            </Fragment>
          )}
        </StyledGameContainerMobile>
      ) : (
        <Fragment>
          <StyledGameContainer>
            <StyledFortuneWheelContainer>
              <ClickHereCircle />
              <StyledPointer src={require(`../../../assets/christmasFortuneWheel/pointeur.png`)} />
              <StyledWheel
                $preRequestSpinning={preRequestSpinning}
                $postRequestSpinning={postRequestSpinning}
                $finalRotationDegree={finalRotationDegree}
                onClick={launchWheel}
                $hasPlayed={!!gameResult}
              />
              <StyledPresents src={require(`../../../assets/christmasFortuneWheel/presents.png`)} />
            </StyledFortuneWheelContainer>
            <StyledRightContainer>
              <StyledGameName src={require('../../../assets/christmasFortuneWheel/gameName.png')} />
              <LogoCard logoSrc={game.logo} />
              {!gameResult && <DescriptionCard game={game} />}
              {gameResult && gameResult?.winner && <WinnerCard gameResult={gameResult} />}
              {gameResult && !gameResult?.winner && <LoserCard gameResult={gameResult} />}
            </StyledRightContainer>
          </StyledGameContainer>
        </Fragment>
      )}
      <Footer game={game} />
    </Fragment>
  );
};

export default FortuneWheel;
