import { styled } from 'styled-components';

export const StyledTitle = styled.div<{ $isMobile: boolean }>`
  font-family: 'Inknut Antiqua', serif;
  font-size: ${({ $isMobile }) => ($isMobile ? '50px' : '92px')};
  color: #d4a26d;
  margin-top: -140px;
  margin-bottom: 50px;
  text-transform: uppercase;
`;

export const StyledText = styled.div<{ $isMobile: boolean }>`
  color: white;
  font-size: 52px;
  font-size: ${({ $isMobile }) => ($isMobile ? '30px' : '52px')};
  max-width: 70%;
  text-align: center;
`;
