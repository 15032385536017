import React from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from '../../../../hooks';
import { TParticiparteResponse } from '../../../../types';

import Card from '../../../common/Card';
import { StyledLoserTitle, StyledText } from '../style';

type TLoserCardProps = {
  gameResult: TParticiparteResponse['data'];
};

const LoserCard = ({ gameResult }: TLoserCardProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Card maxWidth="500px" width={isMobile ? 'fit-content' : ''}>
      <StyledLoserTitle>{t('youLose')}</StyledLoserTitle>

      <StyledText>{gameResult.message}</StyledText>
    </Card>
  );
};

export default LoserCard;
