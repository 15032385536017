import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { TGameData } from '../../../../types';

import { formatDate } from '../../../../helpers';
import { StyledText } from '../style';
import { StyledDatesTitle } from './sytle';

type Props = {
  game: TGameData;
};

const DescriptionCard = ({ game }: Props) => {
  const { t } = useTranslation();
  const startDate = formatDate(new Date(game.startDate), false);
  const endDate = formatDate(new Date(game.endDate), true);

  return (
    <Fragment>
      <StyledDatesTitle
        dangerouslySetInnerHTML={{ __html: t('startsAndEnds', { starts: startDate.toString(), ends: endDate }) }}
      />
      <StyledText dangerouslySetInnerHTML={{ __html: game.description }} />
    </Fragment>
  );
};

export default DescriptionCard;
