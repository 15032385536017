import { Fragment } from 'react';

import { TGameData, TParticipant } from '../../types';

import AdventCalendarGame from './AdventCalendarGame';
import ChristmasFortuneWheelGame from './ChristmasFortuneWheelGame';
import ClassicFortuneWheel from './ClassicWheelGame';

type TGameProps = {
  game: TGameData;
  participant: TParticipant;
  userToken: string;
};

const classicWheelId = 'wheel-fortune-classic';
const estivalWheelId = 'wheel-fortune-estival';
const fortuneWheelId = 'wheel-fortune-christmas';
const adventCalendarId = 'advent-calendar';

const index = ({ game, participant, userToken }: TGameProps) => {
  return (
    <Fragment>
      {game.animationType === classicWheelId && (
        <ClassicFortuneWheel game={game} participant={participant} userToken={userToken} footerColor="white" />
      )}
      {game.animationType === estivalWheelId && (
        <ClassicFortuneWheel
          game={game}
          participant={participant}
          userToken={userToken}
          imagesPath="assets/estivalWheel"
        />
      )}
      {game.animationType === fortuneWheelId && (
        <ChristmasFortuneWheelGame game={game} participant={participant} userToken={userToken} />
      )}
      {game.animationType === adventCalendarId && (
        <AdventCalendarGame game={game} participant={participant} userToken={userToken} />
      )}
    </Fragment>
  );
};

export default index;
