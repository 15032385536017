import React, { Fragment } from 'react';

import { TGameData, TParticipant } from '../../types';

import { isFutureGame, isLastDay, isPastGame } from '../../helpers';
import { translate } from '../../translations';
import Alert from '../common/Alert';

type Props = {
  participant: TParticipant;
  game: TGameData;
};

const Alerts = ({ participant, game }: Props) => (
  <Fragment>
    {!participant.canPlay && !isLastDay(game.endDate) && <Alert title={translate('alreadyPlayedTitle')} />}
    {!participant.canPlay && isLastDay(game.endDate) && <Alert title={translate('alreadyPlayedAndLastDayTitle')} />}
    {!participant.canPlay && game.participationPolicy === 'once' && <Alert title={translate('onceGameTitle')} />}
  </Fragment>
);

export default Alerts;
