import React from 'react';
import { useTranslation } from 'react-i18next';

import { TGameData } from '../../../../types';

import { formatDate } from '../../../../helpers';
import Day from '../Day';
import '../Day/style';
import Footer from '../Footer';
import {
  StyledBottomContainer,
  StyledDates,
  StyledDayContainer,
  StyledGameTitle,
  StyledHaveFunText,
  StyledLogo,
  StyledMobileViewContainer,
  StyledTopContainer,
  StyledWelcomeTitle,
} from './style';

type TMobileViewProps = {
  game: TGameData;
  openBox: () => void;
};

const MobileView = ({ game, openBox }: TMobileViewProps) => {
  const { t } = useTranslation();
  const today = new Date();
  const currentDay = today.getDate().toString();

  const startDate = formatDate(new Date(game.startDate), false);
  const endDate = formatDate(new Date(game.endDate), true);

  return (
    <StyledMobileViewContainer>
      <StyledTopContainer>
        <StyledGameTitle>{t('adventCalendar')}</StyledGameTitle>
        <StyledLogo src={game.logo} alt="logo" />
      </StyledTopContainer>
      <StyledDayContainer>
        <Day day={currentDay} gridArea="" openBox={openBox} />
      </StyledDayContainer>
      <StyledBottomContainer>
        <StyledDates
          dangerouslySetInnerHTML={{
            __html: t('startsAndEnds', { starts: startDate, ends: endDate }),
          }}
        />
        <StyledWelcomeTitle>{t('welcomeToChristmasGame')}</StyledWelcomeTitle>
        <StyledHaveFunText>
          {t('openBoxEveryDay')}
          <b>{t('haveFunAndWinPresents')}</b>
        </StyledHaveFunText>
        <Footer game={game} />
      </StyledBottomContainer>
    </StyledMobileViewContainer>
  );
};

export default MobileView;
