import Day from '../Day';
import { CalendarContainer } from './style';

type TCalendarProps = {
  openBox: () => void;
};

const index = ({ openBox }: TCalendarProps) => {
  return (
    <CalendarContainer>
      <Day day="17" gridArea="1 / 1 / span 2 / span 1" openBox={openBox} />
      <Day day="7" gridArea="1 / 2 / span 1 / span 1" openBox={openBox} />
      <Day day="19" gridArea="1 / 3 / span 1 / span 1" openBox={openBox} />
      <Day day="4" gridArea="1 / 4 / span 1 / span 1" openBox={openBox} />
      <Day day="5" gridArea="2 / 2 / span 1 / span 1" openBox={openBox} />
      <Day day="6" gridArea="2 / 3 / span 2 / span 1" openBox={openBox} />
      <Day day="20" gridArea="2 / 4 / span 2 / span 1" openBox={openBox} />
      <Day day="8" gridArea="3 / 1 / span 1 / span 1" openBox={openBox} />
      <Day day="9" gridArea="3 / 2 / span 1 / span 1" openBox={openBox} />
      <Day day="10" gridArea="4 / 3 / span 1 / span 1" openBox={openBox} />
      <Day day="11" gridArea="4 / 4 / span 1 / span 1" openBox={openBox} />
      <Day day="12" gridArea="4 / 2 / span 2 / span 1" openBox={openBox} />
      <Day day="3" gridArea="5 / 1 / span 1 / span 1" openBox={openBox} />
      <Day day="16" gridArea="4 / 1 / span 1 / span 1" openBox={openBox} />
      <Day day="15" gridArea="1 / 5 / span 2 / span 1" openBox={openBox} />
      <Day day="15" gridArea="1 / 5 / span 2 / span 1" openBox={openBox} />
      <Day day="14" gridArea="3/ 5 / span 1 / span 1" openBox={openBox} />
      <Day day="1" gridArea="5/ 5 / span 1 / span 1" openBox={openBox} />
      <Day day="18" gridArea="6/ 1 / span 1 / span 1" openBox={openBox} />
      <Day day="13" gridArea="6/ 2 / span 1 / span 1" openBox={openBox} />
      <Day day="2" gridArea="5/ 3 / span 2 / span 1" openBox={openBox} />
      <Day day="21" gridArea="5/ 4 / span 1 / span 1" openBox={openBox} />
      <Day day="22" gridArea="4/ 5 / span 1 / span 1" openBox={openBox} />
      <Day day="23" gridArea="6/ 4 / span 1 / span 1" openBox={openBox} />
      <Day day="24" gridArea="6/ 5 / span 1 / span 1" openBox={openBox} />
    </CalendarContainer>
  );
};

export default index;
