import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { TParticiparteResponse } from '../../../../types';

import { StyledLoserTitle, StyledText } from '../style';

type TLoserCardProps = {
  gameResult: TParticiparteResponse['data'];
};

const LoserCard = ({ gameResult }: TLoserCardProps) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <StyledLoserTitle>{t('youLose')}</StyledLoserTitle>
      <StyledText>{gameResult.message}</StyledText>
    </Fragment>
  );
};

export default LoserCard;
