import { useTranslation } from 'react-i18next';

import { useIsMobile } from '../../../../hooks';
import { TParticiparteResponse } from '../../../../types';

import Backdrop from '../Backdrop';
import { StyledBottomText, StyledContainer, StyledPresentImage, StyledSubtitle, StyledTitle } from './style';

type TWinnerResult = {
  gameResult: TParticiparteResponse['data'];
};

const WinnerResult = ({ gameResult }: TWinnerResult) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Backdrop bottomText={t('checkYourSpams')}>
      <StyledContainer>
        <div>
          <StyledTitle $isMobile={isMobile}>{t('youWon')}</StyledTitle>
          <StyledSubtitle $isMobile={isMobile}>{gameResult.prize.name}</StyledSubtitle>
        </div>
        <StyledPresentImage src={gameResult.prize.image} $isMobile={isMobile} />
        <StyledBottomText $isMobile={isMobile}>{t('checkYourEmails')}</StyledBottomText>
      </StyledContainer>
    </Backdrop>
  );
};

export default WinnerResult;
