import { styled } from 'styled-components';

export const StyledFooter = styled.div`
  text-align: center;
  padding-bottom: 20px;
  color: #696969;
  a {
    color: #696969;
  }
`;
