import { styled } from 'styled-components';

export const StyledWinnerTitle = styled.div`
  font-family: Lobster;
  text-align: center;
  font-size: 120px;
  color: #fbcf63;
  text-shadow: -3px -3px 0 #f9b818, 3px -3px 0 #f9b818, -3px 3px 0 #f9b818, 3px 3px 0 #f9b818;
`;

export const StyledGiftContainer = styled.img`
  padding: 10px;
  max-width: 80%;
  max-height: 80%;
`;
