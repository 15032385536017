import axios from 'axios';
import { Fragment, useState } from 'react';

import { useIsMobile } from '../../../hooks';
import { TGameData, TParticipant, TParticiparteResponse } from '../../../types';

import { participate } from '../../../apiRoutes';
import Calendar from './CalendarContainer';
import InformationContainer from './InformationContainer';
import LoserResult from './LoserResult';
import MobileView from './MobileView';
import WinnerResult from './WinnerResult';
import { StyledCalendarContainer } from './style';

interface TFortuneWheelProps {
  game: TGameData;
  participant: TParticipant;
  userToken: string;
}

const FortuneWheel: React.FC<TFortuneWheelProps> = ({ game, userToken }: TFortuneWheelProps) => {
  const isMobile = useIsMobile();

  const [gameResult, setGameResult] = useState<TParticiparteResponse['data'] | false>(false);

  const openBox = async () => {
    const { data }: TParticiparteResponse = await axios.get(participate(userToken));

    setGameResult(data);
  };

  return (
    <Fragment>
      {isMobile ? (
        <MobileView game={game} openBox={openBox} />
      ) : (
        <StyledCalendarContainer>
          <Calendar openBox={openBox} />
          <InformationContainer game={game} />
        </StyledCalendarContainer>
      )}
      {gameResult && (
        <Fragment>
          {gameResult.winner && <WinnerResult gameResult={gameResult} />}
          {!gameResult.winner && <LoserResult />}
        </Fragment>
      )}
    </Fragment>
  );
};

export default FortuneWheel;
