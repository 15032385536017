import React from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from '../../../../hooks';
import { TGameData } from '../../../../types';

import { StyledFooter } from './style';

type TFooterProps = {
  game: TGameData;
};

const index = ({ game }: TFooterProps) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  return (
    <StyledFooter $isMobile={isMobile}>
      <div>
        {t('propulsedBy')} {game.client} - <br />
        <a href={game.sourceProgramUrl} target="_blank" rel="noreferrer">
          {game.sourceProgramName}
        </a>{' '}
        - {t('consultRules')}{' '}
        <a href={game.gameRule} target="_blank" rel="noreferrer">
          {t('gameRules')}
        </a>
      </div>
      {t('loyaltyCopyrights')}
    </StyledFooter>
  );
};

export default index;
