import { styled } from 'styled-components';

export const StyledCard = styled.div<{
  $maxWidth?: string;
  $marginBottom?: string;
  $minHeight?: string;
  $padding?: string;
  $isMobile?: boolean;
  $noShadows?: boolean;
  $width?: string;
  $borderRadius?: string;
  $margin?: string;
}>`
  background: #fff;
  border-radius: ${({ $borderRadius }) => ($borderRadius ? $borderRadius : '15px')};
  z-index: 1;
  width: ${({ $width }) => ($width ? $width : '100%')};
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: ${({ $noShadows }) => ($noShadows ? 'none' : '0px 4px 4px 0px rgba(0, 0, 0, 0.25)')};
  padding: ${({ $padding }) => ($padding ? $padding : '20px 24px')};
  max-width: ${({ $maxWidth }) => $maxWidth && $maxWidth};
  margin-bottom: ${({ $marginBottom }) => $marginBottom && $marginBottom};
  min-height: ${({ $minHeight }) => $minHeight && $minHeight};
  margin-top: ${({ $isMobile }) => $isMobile && '40px'};
  margin: ${({ $margin }) => $margin && $margin};
`;
