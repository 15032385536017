import { useTranslation } from 'react-i18next';

import { TGameData } from '../../../../types';

import { formatDate } from '../../../../helpers';
import Footer from '../Footer';
import {
  StyledClientLogo,
  StyledDates,
  StyledGameTitle,
  StyledInformationContainer,
  StyledInformationSubContainer,
  StyledText,
} from './style';

type Props = { game: TGameData };

const InformationContainer = ({ game }: Props) => {
  const { t } = useTranslation();
  const startDate = formatDate(new Date(game.startDate), false);
  const endDate = formatDate(new Date(game.endDate), true);

  return (
    <StyledInformationContainer>
      <StyledInformationSubContainer>
        <StyledGameTitle>{t('adventCalendar')}</StyledGameTitle>
        <StyledClientLogo src={game.logo} />
        <StyledDates
          dangerouslySetInnerHTML={{ __html: t('startsAndEnds', { starts: startDate.toString(), ends: endDate }) }}
        />
        <StyledText dangerouslySetInnerHTML={{ __html: game.description }} />
      </StyledInformationSubContainer>
      <Footer game={game} />
    </StyledInformationContainer>
  );
};

export default InformationContainer;
