import { useTranslation } from 'react-i18next';

import { useIsMobile } from '../../hooks';

import { StyledLogo, StyledNoGameAvailableContainer, StyledText } from './style';

const NoGameAvailable = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <StyledNoGameAvailableContainer>
      <StyledLogo src={require(`../../assets/common/mainLogo.png`)} isMobile={isMobile} />
      <StyledText isMobile={isMobile}>
        <b>{t('gameNotAvailable')}</b>
      </StyledText>
      <StyledText isMobile={isMobile}>{t('didNotStartOrAlreadyFinished')}</StyledText>
    </StyledNoGameAvailableContainer>
  );
};

export default NoGameAvailable;
