import { useState } from 'react';

import { useIsMobile } from '../../../../hooks';

import { StyledBackDoor, StyledDayContainer, StyledDaySubContainer } from './style';

type Props = {
  day: string;
  openBox: () => void;
  gridArea: string;
};

const index = ({ day, openBox, gridArea }: Props) => {
  const isMobile = useIsMobile();

  const today = new Date().getDate().toString();
  const isToday = today === day;
  const isPastDay = Number(today) > Number(day);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenBox = () => {
    if (!isToday) return;
    setIsOpen(true);
    setTimeout(() => {
      openBox();
    }, 200);
  };

  return (
    <StyledBackDoor $gridArea={gridArea} $isOpen={isOpen} $isMobile={isMobile}>
      <StyledDayContainer
        onClick={handleOpenBox}
        $gridArea={gridArea}
        $isToday={isToday}
        $isOpen={isOpen}
        $isPastDay={isPastDay}
        $isMobile={isMobile}
      >
        <StyledDaySubContainer $isOpen={isOpen} $isPastDay={isPastDay} $isMobile={isMobile}>
          {!isPastDay && day}
        </StyledDaySubContainer>
      </StyledDayContainer>
    </StyledBackDoor>
  );
};

export default index;
