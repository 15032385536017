import { styled } from 'styled-components';

export const StyledInformationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const StyledInformationSubContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #222b3a;
  width: 500px;
  height: 100%;
  padding: 60px 20px;
`;

export const StyledGameTitle = styled.div`
  font-family: 'Inknut Antiqua', serif;
  text-transform: uppercase;
  font-size: 60px;
  line-height: 64px;
  text-align: center;
  color: white;
  margin-bottom: 28px;
`;

export const StyledClientLogo = styled.img`
  height: 100px;
  margin-bottom: 28px;
`;

export const StyledDates = styled.div`
  color: white;
  uppercase: true;
  font-weight: bold;
  margin-bottom: 38px;
  text-align: center;
  font-size: 24px;
`;

export const StyledText = styled.div`
  color: #d4a26d;
  font-size: 28px;
  text-align: center;
  max-width: 80%;
`;
