import { StyledLogo } from './style';

interface TLogoCardProps {
  logoSrc: string;
}

const index = ({ logoSrc }: TLogoCardProps) => {
  return <StyledLogo src={logoSrc} />;
};

export default index;
