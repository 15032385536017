import React from 'react';
import { useTranslation } from 'react-i18next';

import { TGameData } from '../../../../types';

import { StyledFooter } from './style';

type TFooterProps = {
  game: TGameData;
};

const index = ({ game }: TFooterProps) => {
  const { t } = useTranslation();

  return (
    <StyledFooter>
      {t('propulsedBy')} {game.client} -{' '}
      <a href={game.sourceProgramUrl} target="_blank" rel="noreferrer">
        {game.sourceProgramName}
      </a>{' '}
      - {t('consultRules')}{' '}
      <a href={game.gameRule} target="_blank" rel="noreferrer">
        {t('gameRules')}
      </a>{' '}
      <br />
      {t('loyaltyCopyrights')}
    </StyledFooter>
  );
};

export default index;
