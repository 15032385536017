import { styled } from 'styled-components';

export const StyledFooter = styled.div<{ $isMobile: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: ${({ $isMobile }) => ($isMobile ? '400px' : '500px')};
  text-align: center;
  align-items: center;
  color: white;
  gap: 20px;
  a {
    color: white;
  }
`;
