import { styled } from 'styled-components';

export const StyledFooter = styled.div<{ $footerColor?: string }>`
  text-align: center;
  padding-bottom: 20px;
  color: ${({ $footerColor }) => $footerColor || '#696969'};
  a {
    color: ${({ $footerColor }) => $footerColor || '#696969'};
  }
`;
