import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { TParticiparteResponse } from '../../../../types';

import { StyledText } from '../style';
import { StyledGiftContainer, StyledWinnerTitle } from './sytle';

type TWinnerCardProps = {
  gameResult: TParticiparteResponse['data'];
};

const WinnerCard = ({ gameResult }: TWinnerCardProps) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <StyledWinnerTitle>Gagné !</StyledWinnerTitle>
      <h1>{gameResult.prize.name}</h1>
      <StyledGiftContainer src={gameResult.prize.image} />
      <StyledText>{t('checkYourEmails')}</StyledText>
    </Fragment>
  );
};

export default WinnerCard;
