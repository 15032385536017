import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import frTranslation from './languages/frenchTranslations.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    fallbackLng: 'fr',
    debug: true,
    resources: {
      fr: {
        translation: frTranslation,
      },
    },
    detection: {
      order: ['localStorage'],
      caches: [],
    },
  });

export const translate = (key: string, options = {}) => {
  return i18n.t(key, options);
};

export default i18n;
