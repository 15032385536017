import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@mui/material/styles';

import { StyledContainer } from './style';

type TAlertProps = {
  title: string;
};

const index = ({ title }: TAlertProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog open maxWidth="xl" fullScreen={fullScreen}>
      <StyledContainer>
        <h2>{title}</h2>
      </StyledContainer>
    </Dialog>
  );
};

export default index;
