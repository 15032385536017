import { styled } from 'styled-components';

export const StyledCalendarContainer = styled.div`
  display: grid;
  grid-template-columns: 23fr 18fr;
  gap: 20px;
  padding: 60px;
  min-height: calc(100vh - 120px);
  background-image: url(${require(`../../../assets/adventCalendar/background.png`)});
  background-size: 100%;
  background-position: 160px;
`;
