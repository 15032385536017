import { styled } from 'styled-components';

export const StyledNoGameAvailableContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #142669;
`;

export const StyledLogo = styled.img<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '300px' : '500px')};
  height: ${({ isMobile }) => (isMobile ? '180px' : '300px')};
  padding-top: 100px;
  margin-bottom: 100px;
`;

export const StyledText = styled.div<{ isMobile: boolean }>`
  color: white;
  font-size: 36px;
  text-align: center;
`;
