export const isPastGame = (endDate: string) => {
  const today = new Date().getTime();
  const end = new Date(endDate).getTime();

  return today > end;
};

export const isFutureGame = (startDate: string) => {
  const today = new Date().getTime();
  const start = new Date(startDate).getTime();

  return today < start;
};

export const isLastDay = (endDate: string) => {
  const today = new Date().getTime();
  const end = new Date(endDate).getTime();

  return today === end;
};

export const formatDate = (date: Date, isEndDate: boolean) => {
  const day = String(date.getDate()).padStart(2, '0').toUpperCase();
  const month = date.toLocaleDateString('fr-FR', { month: 'long' }).toUpperCase();
  return `${day} ${month}${isEndDate ? ` ${date.getFullYear()}` : ''}`;
};
