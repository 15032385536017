import React from 'react';

import { useIsMobile } from '../../../hooks';

import { StyledCard } from './style';

type TCardProps = {
  children: React.ReactNode;
  maxWidth?: string;
  marginBottom?: string;
  minHeight?: string;
  padding?: string;
  noShadows?: boolean;
  width?: string;
  borderRadius?: string;
  margin?: string;
};

const Card = ({
  children,
  maxWidth,
  marginBottom,
  minHeight,
  padding,
  noShadows,
  width,
  borderRadius,
  margin,
}: TCardProps) => {
  const isMobile = useIsMobile();

  return (
    <StyledCard
      $maxWidth={maxWidth}
      $marginBottom={marginBottom}
      $minHeight={minHeight}
      $padding={padding}
      $isMobile={isMobile}
      $noShadows={noShadows}
      $width={width}
      $borderRadius={borderRadius}
      $margin={margin}
    >
      {children}
    </StyledCard>
  );
};

export default Card;
