import React from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from '../../../../hooks';
import { TGameData } from '../../../../types';

import { formatDate } from '../../../../helpers';
import Card from '../../../common/Card';
import { StyledText } from '../style';
import { StyledDatesTitle } from './sytle';

type Props = {
  game: TGameData;
};

const DescriptionCard = ({ game }: Props) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const startDate = formatDate(new Date(game.startDate), false);
  const endDate = formatDate(new Date(game.endDate), true);

  return (
    <Card
      maxWidth="500px"
      width={isMobile ? 'fit-content' : ''}
      padding={isMobile ? '20px 24px' : '100px 24px'}
      noShadows={isMobile}
    >
      <StyledDatesTitle
        dangerouslySetInnerHTML={{ __html: t('startsAndEnds', { starts: startDate.toString(), ends: endDate }) }}
      />
      <StyledText dangerouslySetInnerHTML={{ __html: game.description }} />
    </Card>
  );
};

export default DescriptionCard;
