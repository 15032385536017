import { styled } from 'styled-components';

export const StyledBackDoor = styled.div<{ $gridArea: string; $isOpen: boolean; $isMobile: boolean }>`
  background-color: transparent;
  justify-content: center;
  align-items: center;
  display: flex;
  background: red;
  background: ${(props) => (props.$isOpen ? 'black' : 'transparent')};
  grid-area: ${(props) => props.$gridArea};
  width: ${(props) => props.$isMobile && '300px'};
  height: ${(props) => props.$isMobile && '300px'};
`;

export const StyledDayContainer = styled.div<{
  $gridArea: string;
  $isToday: boolean;
  $isOpen: boolean;
  $isPastDay: boolean;
  $isMobile: boolean;
}>`
  position: relative;
  border: ${(props) =>
    props.$isMobile ? '4px solid white' : props.$isToday ? '2px dashed orange' : '2px dashed white'};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding: 18px;
  width: calc(100% - 18px);
  height: calc(100% - 36px);
  transform: ${(props) =>
    props.$isOpen && 'perspective(1200px) translateZ(0px) translateX(0px) translateY(0px) rotateY(-98deg);'};
  transform-origin: left;
  color: ${(props) => (props.$isOpen ? 'black' : 'white')};
  grid-area: ${(props) => props.$gridArea};
  background: ${(props) => (props.$isOpen ? '#222b3a' : props.$isPastDay ? 'rgba(30, 29, 39, 0.7)' : 'transparent')};
  &:hover {
    cursor: pointer;
    cursor: ${(props) => (props.$isToday && !props.$isOpen ? 'pointer' : 'not-allowed')};
    background: ${(props) =>
      props.$isOpen
        ? 'black'
        : props.$isToday
        ? 'rgba(30, 29, 39)'
        : props.$isPastDay
        ? 'rgba(30, 29, 39, 0.7)'
        : 'transparent'};
    transition: 0.4s;
  }
`;

export const StyledDaySubContainer = styled.div<{ $isOpen: boolean; $isPastDay: boolean; $isMobile: boolean }>`
  border: 1px solid white;
  border: ${(props) => (props.$isMobile ? '2px dashed white' : props.$isOpen ? 'none' : '1px solid white')};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.$isMobile
      ? 'rgb(245,245,245, 0.3)'
      : props.$isOpen
      ? '#222b3a'
      : props.$isPastDay
      ? 'rgba(30, 29, 39, 0.7)'
      : 'rgba(30, 29, 39, 0.5)'};
  font-size: ${(props) => (props.$isMobile ? '180px' : '40px')};
  font-weight: 600;
`;
