import styled, { css } from 'styled-components';

import { getSpinKeyframes } from '../../../animations';

export const StyledGameContainer = styled.div`
  @-webkit-keyframes moving-background {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -2880px 0;
    }
  }
  @-moz-keyframes moving-background {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -2880px 0;
    }
  }
  @-ms-keyframes moving-background {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -2880px 0;
    }
  }
  @-o-keyframes moving-background {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -2880px 0;
    }
  }
  @keyframes moving-background {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -2880px 0;
    }
  }

  display: grid;
  grid-template-columns: 1fr 1fr;
  background-image: url(${require(`../../../assets/christmasFortuneWheel/background.png`)});
  background-size: auto 800px;
  background-position: center -135px;
  background-repeat: repeat-x;
  z-index: 30;
  padding: 40px 0;
  -webkit-animation: moving-background 16s steps(10000) infinite;
  -moz-animation: moving-background 16s steps(10000) infinite;
  -ms-animation: moving-background 16s steps(10000) infinite;
  -o-animation: moving-background 16s steps(10000) infinite;
  animation: moving-background 16s steps(10000) infinite;
`;

export const StyledFortuneWheelContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StyledPointer = styled.img`
  background-size: contain;
  height: 55px;
  width: 32px;
  z-index: 10;
  position: relative;
  margin-bottom: -25px;
`;

export const StyledGameName = styled.img<{ $isMobile?: boolean }>`
  margin-bottom: 15px;
  max-width: ${({ $isMobile }) => $isMobile && '80%'};
  margin: ${({ $isMobile }) => $isMobile && '0 10%'};
`;

export const StyledWheel = styled.div<{
  $preRequestSpinning: boolean;
  $postRequestSpinning: boolean;
  $finalRotationDegree: number;
  $hasPlayed: boolean;
  $isMobile?: boolean;
}>`
  background-image: url(${require(`../../../assets/christmasFortuneWheel/wheel.png`)});
  width: ${({ $isMobile }) => ($isMobile ? '300px' : '500px')};
  height: ${({ $isMobile }) => ($isMobile ? '300px' : '500px')};
  background-size: contain;
  position: relative;
  z-index: 1;
  cursor: pointer;
  cursor: ${({ $hasPlayed }) => ($hasPlayed ? 'not-allowed' : 'pointer')};

  ${({ $preRequestSpinning, $finalRotationDegree, $postRequestSpinning }) =>
    $preRequestSpinning
      ? css`
          animation: ${getSpinKeyframes(940)} 3s linear infinite;
        `
      : $finalRotationDegree && $postRequestSpinning
      ? css`
          animation: ${getSpinKeyframes($finalRotationDegree)} 3s linear 1 forwards;
        `
      : 'none'};
`;

export const StyledPresents = styled.img<{ $isMobile?: boolean }>`
  background-size: contain;
  height: ${({ $isMobile }) => ($isMobile ? '215px' : '431px')};
  width: ${({ $isMobile }) => ($isMobile ? '327px' : '658px')};
  z-index: 0;
  position: relative;
  margin-top: ${({ $isMobile }) => ($isMobile ? '-100px' : '-200px')};
  margin-left: 42px;
`;

export const StyledRightContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const StyledText = styled.div`
  font-size: 24px;
  text-align: center;
`;

export const StyledGameContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${require(`../../../assets/christmasFortuneWheel/background.png`)});
  background-size: 500px 500px;
  background-repeat: repeat-x;
  z-index: 30;
  padding: 40px 0;
`;

export const StyledLoserTitle = styled.div`
  font-family: Lobster;
  text-align: center;
  font-size: 120px;
  color: #fbcf63;
  text-shadow: -4px -4px 0 #f9b818, 4px -4px 0 #f9b818, -4px 4px 0 #f9b818, 4px 4px 0 #f9b818;
`;
