import React from 'react';

import { useIsMobile } from '../../../../hooks';

import Card from '../../../common/Card';
import { StyledLogo } from './style';

interface TLogoCardProps {
  logoSrc: string;
}

const index = ({ logoSrc }: TLogoCardProps) => {
  const isMobile = useIsMobile();
  return (
    <Card maxWidth={isMobile ? '250px' : '500px'} marginBottom="14px">
      <StyledLogo src={logoSrc} />
    </Card>
  );
};

export default index;
