import { Backdrop } from '@mui/material';
import React from 'react';

import { useIsMobile } from '../../../../hooks';

import { StyledBottomText, StyledContainer } from './style';

type TBackdropsProps = {
  children: React.ReactNode;
  bottomText?: string;
};

const index = ({ children, bottomText }: TBackdropsProps) => {
  const isMobile = useIsMobile();

  return (
    <Backdrop sx={{ zIndex: 4000, backgroundColor: 'rgba(34, 43, 58, 0.8)', flexDirection: 'column' }} open>
      <StyledContainer $isMobile={isMobile}>{children}</StyledContainer>
      {bottomText && <StyledBottomText>{bottomText}</StyledBottomText>}
    </Backdrop>
  );
};

export default index;
