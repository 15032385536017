import { useTranslation } from 'react-i18next';

import { useIsMobile } from '../../../../hooks';

import { StyledText, StyledTitle } from './styles';

import Backdrop from '../Backdrop';

const index = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const today = new Date().getDate();
  const text = today === 24 ? t('gameEndsTodaySeeYouOnTheNextGame') : t('tryAgainTommorow');

  return (
    <Backdrop>
      <StyledTitle $isMobile={isMobile}>{t('youLose')}</StyledTitle>
      <StyledText $isMobile={isMobile}>{text}</StyledText>
    </Backdrop>
  );
};

export default index;
